import React, { useState, useEffect, FC, useRef, useMemo, useCallback, ReactNode, MouseEvent, createElement, useLayoutEffect, useContext, ReactElement } from "react";


// import "survey-core/modern.min.css";
import "survey-core/defaultV2.min.css";
import { useParams, useSearchParams } from "react-router-dom";
import { Button, Result, Spin } from "antd";
import queryString from "query-string";

//REGISTER CUSTOM QUESTIONS
import { SurveyTaker, H } from "hanover-survey-library";
import _, { cloneDeep, isEmpty } from "lodash";
import ReCAPTCHA from "react-google-recaptcha";

import "./../../App.css";
import { SurveyTakerService } from "./SurveyTaker.service";
import { RECAPTCHA_SRC_URL, RECAPTCHAV3_KEY, REV_ID_SRC_URL } from "../../constants/env";
import { HVariable } from "../../survey-js-extensions/types/variables.types";
import { parseJwt, isScriptPresent, addScript, jsonToObj } from "../../utils/utils";
import { RevIdCallResponse } from "../RevID/RevID.types";
import { callRevIdService } from "../RevID/RevID.utils";
import { getLastTokenFromSurveyStorage, setLocalStorageItem, localStorageKeys, removeLocalStorageItem, getLocalStorageItem, clearLocalStorageByPattern } from "./types/constant";
import { SurveyResponse, SurveyProps } from "./types/survey.types";
import { disableNextPageButtonOrCompleted, enableNextPageButtonOrCompleted } from "./utils/survey.utils";
import { countersToObject } from "../../survey-js-extensions/utils/variables.utils";
import { removeMatchingPropertiesAndValues } from "./survey.utils";

const TakeSurveyWrapper = () => {
  const { orgId, token } = useParams();
  const [searchParams] = useSearchParams();

  const fraudRevIdDupeThreashold = 75;
  const [loading, setLoading] = useState(false);
  const surveyTakerService = new SurveyTakerService();
  const [surveyResponse, setSurveyResponse] = useState<SurveyResponse>(
    {} as SurveyResponse
  );
  const [randomizeActions, setRandomizeActions] = useState<any>([]);
  const [surveyProps, setSurveyProps] = useState<SurveyProps>({} as SurveyProps);
  const [prevResponse, setPreviousResponse] = useState(null);
  const [isSurveyTaken, setIsSurveyTaken] = useState(true);
  const [errorMessage, setErrorMessage] = useState(
    "There are some problems with your operation. Click below to take the survey again?"
  );
  const [errorClose, setErrorClose] = useState(false);
  const queryParams = queryString.parse(window.location.search);

  const reRef = useRef<ReCAPTCHA | null>(null)
  const surveyDesign = async () => {
    localStorage.removeItem("navigateToUrl");
    setLoading(true);
    let response;
    try {
      const usrToken = searchParams.get('t');
      if (usrToken) {
        response = await surveyTakerService.getSurveyTokenForIndividualLinks(usrToken, {redirect: false,...queryParams});
      }

      if ((token && token !== "" && orgId && orgId !== "")) {
        response = await surveyTakerService.getSurveyToken(token, orgId, {redirect: false,...queryParams});
      }
      
      const { data }: any = response;
      if (data && data.jwt) {
        setLoading(false);
        const token = usrToken ? data.jwt : getLastTokenFromSurveyStorage(data.jwt);
        const { survey } = parseJwt(token);
        setLocalStorageItem(localStorageKeys.SURVEY_TOKEN, token, {takerId: survey.takerId});
        setLocalStorageItem(localStorageKeys.SURVEY_ID, survey.designId, {takerId: survey.takerId});
        fetchSurveyJson(token);
      } else {
        setLoading(false);
        setErrorClose(true);
      }
    } catch (error: any) {
      if( error?.response?.data?.message) {
        setErrorMessage(error?.response?.data?.message);
      }
      setLoading(false);
      setErrorClose(true);
      setIsSurveyTaken(false);
    }
  };

  const fetchSurveyJson = async (token: string | "") => {
    setIsSurveyTaken(true);
    setLoading(true);
    if (token) {
      const response = await surveyTakerService.fetchSurvey(token);
      let surveyPropsData;

      if (!response.data) {
        return;
      }

      const flow = H.upgradeFlowEngineToLatestVersion(cloneDeep(response.data.survey.flow));

      if ((!response.data.survey?.flowPosition || isEmpty(response.data.survey?.flowPosition)) && H.checkRandomizationExists(flow)) {
        const { newRandomizeCount, newFlow } = H.processRandomizeFlow(flow, response.data.survey.randomizeCount);

        response.data.survey.flow = newFlow;
        
        setRandomizeActions(H.compareObjectCounts(response.data.survey.randomizeCount, newRandomizeCount));
      }

      setSurveyResponse(response.data);
      
      if (response.data.surveyToken?.token) {
        const token = response.data.surveyToken.token;
        surveyPropsData = parseJwt(token)?.survey;
        removeLocalStorageItem(localStorageKeys.USER_SURVEY_FLOW, { takerId: surveyPropsData.takerId });
        //sync server flowPosition with localStorage.
        if (!_.isEmpty(response.data.survey?.flowPosition)) {
          setLocalStorageItem(localStorageKeys.USER_SURVEY_FLOW, JSON.stringify(response.data.survey?.flowPosition), { takerId: surveyPropsData.takerId });
        }
        setLocalStorageItem(localStorageKeys.SURVEY_TOKEN, token, {takerId: surveyPropsData.takerId});
        // This change only for Recaptcha question type
        document.body.dataset.token = token; 
        document.body.dataset.surveyId = surveyPropsData.designId;
        document.body.dataset.takerId = surveyPropsData.takerId;
        setSurveyProps(surveyPropsData);

        if (response.data.survey?.contactData) {
          window.h.variablesStore.patchValues(response.data.survey?.contactData);
        }

        if (response.data.survey?.data) {
          setPreviousResponse(response.data.survey?.data);
        }

        if (response.data.survey?.h_data) {
          const { h_finalstatus, h_flags} = response.data.survey?.h_data;

          if (h_finalstatus) {window.h.variablesStore.setValue(HVariable.FinalStatus, h_finalstatus);}
          if (h_flags) {window.h.variablesStore.setValue(HVariable.Flags, h_flags);}
        }

        if (response.data.survey?.PannelId) {
          window.h.variablesStore.setValue(HVariable.PanelId, response.data.survey?.PannelId);
        }
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    let PanelistID = surveyResponse?.survey?.PannelId;

    if (PanelistID) {
      window.h.variablesStore.setValue(HVariable.PanelId, PanelistID);
    }

    if (!surveyProps?.useRelevantID) {
      return;
    }

    if (!PanelistID) {
      PanelistID = surveyResponse?.survey?.RevPannelId || "";
    }

    const ClientID = surveyResponse?.survey?.RelevantIDClientId;
    const SurveyID = surveyProps?.designId;

    // add google recaptcha v3 script
    if (!isScriptPresent('recaptchaScript')) {
      addScript(`${RECAPTCHA_SRC_URL}?render=${RECAPTCHAV3_KEY}`, 'recaptchaScript');

      let retryCount = 0;
      const maxRetries = 5;

      const performReCaptchaAndSetData = async () => {
        if (retryCount >= maxRetries) {
          return;
        }

        if (!window.h.variablesStore.getValue(HVariable.ReCaptchaV3)) {
          try {
            const captchaValidationResponse = await executeRecaptcha();
            const captchaScore = captchaValidationResponse?.responseResponse?.score;
            window.h.variablesStore.setValue(HVariable.ReCaptchaV3, captchaScore);
          } catch (error) {
            retryCount++;
            setTimeout(performReCaptchaAndSetData, 5000);
          }
        }
      };

      performReCaptchaAndSetData();
    }

    if (ClientID && PanelistID && SurveyID && !isScriptPresent('revIdScript')) {
      addScript(REV_ID_SRC_URL, 'revIdScript');

      const requestStartDate: Date = new Date();

      callRevIdService({ClientID, PanelistID, SurveyID})
        .then((revIdResponse: RevIdCallResponse) => {
          setRevIdData(revIdResponse as any);
          window.h.variablesStore.setValue(HVariable.IdDuplicate, revIdResponse?.FraudRiskProbability >= fraudRevIdDupeThreashold || !revIdResponse?.IsNew ? 1 : 0);
          window.h.variablesStore.setValue(HVariable.FraudRisk, revIdResponse.FraudRiskProbability);
          window.h.variablesStore.setValue(HVariable.RevIdDuplicateScore, revIdResponse.Score);
        }).catch((error: any) => {
          setRevIdData({error: true} as any);
        })
        .finally(() => {
          setRevIdData({
            ...getRevIdData(),
            RequestStartDate: requestStartDate,
            RequestEndDate: new Date(),
            RequestDuration: Math.abs(new Date() as any - (requestStartDate as any))
          } as RevIdCallResponse);
        });
    }
  }, [surveyProps]);


  const setRevIdData = (value: RevIdCallResponse | undefined): void => {
    setLocalStorageItem(localStorageKeys.REV_DATA, JSON.stringify(value), {takerId: surveyProps.takerId});
  };

  const getRevIdData = (): RevIdCallResponse | undefined => {
    const data = getLocalStorageItem(localStorageKeys.REV_DATA, {takerId: surveyProps.takerId});
    if (data !== "undefined") {
      return JSON.parse(data as string);
    }
    return;
  };

  type AnyObject = { [key: string]: any };

  const removeCustomSuffix = (obj: AnyObject): AnyObject => {
    return Object.entries(obj).reduce((acc: AnyObject, [key, value]) => {
      const newKey = key.endsWith('-custom') ? key.slice(0, -7) : key;
      acc[newKey] = value;
      return acc;
    }, {});
  };

  const surveyComplete = async (sender: { data: any }, surveyFlowCtrl: any) => {
    let formattedData = removeCustomSuffix(sender.data)
    await saveSurveyResults(formattedData, surveyFlowCtrl);
  };

  const saveSurveyResults = async (json: any, surveyFlowCtrl: any) => {
    const token = getLocalStorageItem(localStorageKeys.SURVEY_TOKEN, {takerId: surveyProps.takerId}) || "";
    const redirectUrl = getLocalStorageItem(localStorageKeys.FLOW_REDIRECT, {takerId: surveyProps.takerId});
    
    if (surveyFlowCtrl.allowDeleteRespondentData) {
      await deleteSurveyResponse();

      if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      return;
    }

    const body = prepareSurveyPayload(json, false, surveyFlowCtrl);
    if (token && body.surveyResponse) {
      const response: any = await surveyTakerService.submitSurvey(token, body);

      // NOTE!!!!! - keep redirectUrl above clearLocalStorageByPattern as it clears all local storage
      clearLocalStorageByPattern(surveyProps.takerId);
      if (response.data.response.id) {
        const urlFromStorage = localStorage.getItem("navigateToUrl");
        const url = urlFromStorage !== "undefined" ? urlFromStorage : surveyResponse?.survey?.modaljson?.navigateToUrl;
        setLoading(false);
        setIsSurveyTaken(true);
        setRevIdData(undefined);

        if (redirectUrl) {
          window.location.href = redirectUrl;
        }
      } else if (response.data.statusCode == 400) {
        setLoading(false);
        setIsSurveyTaken(false);
        setErrorMessage(
          `${response.data.message[0]}. Click below to take the survey again?`
        );
      }
    } else {
      setLoading(false);
      setIsSurveyTaken(false);
      setErrorMessage(
        "There are some problems with your operation. Click below to take the survey again?"
      );
    }
  };
  
  const executeRecaptcha = async () => {
      const captchaResponse = await reRef.current?.executeAsync();
      const token = getLocalStorageItem(localStorageKeys.SURVEY_TOKEN, {takerId: surveyProps.takerId});
      const surveyId = getLocalStorageItem(localStorageKeys.SURVEY_ID, {takerId: surveyProps.takerId});

      const res = await surveyTakerService.validationTokenCaptcha(token || "", {
        projectID: surveyId,
        captchaResponse: captchaResponse,
        invisible: true,
      });
  
      if (res.data && reRef) {
        reRef.current?.reset();
      }
  
      return res.data;
  };

  const prepareSurveyPayload = (json: any = {}, isPartial: boolean, surveyFlowCtrl: any) => {
    const { innerWidth, innerHeight } = window;
    const userflowPosition = getLocalStorageItem(localStorageKeys.USER_SURVEY_FLOW, {takerId: surveyProps.takerId});
    const flowPosition = jsonToObj(userflowPosition);
    const counters = countersToObject([...surveyResponse.survey.counters, ...surveyResponse.survey.quotaCounters]);
    const processedJson = removeMatchingPropertiesAndValues(json, counters);

    const payload = {
      ...(isPartial ? {flowPosition: {
        ...flowPosition,
        ...(flowPosition.noOfPagesRender === 0 ? {randomizeActions: randomizeActions} : {}) }
      } : {}),
      surveyResponse: {
        ...processedJson,
        ...(surveyProps?.useRelevantID && { h_revdata: getRevIdData() }),
        ...{ h_data: window.h.variablesStore.getValuesForPayload()},
      },
      isPartial: isPartial,
      pageIndex: surveyFlowCtrl.noOfPagesRender,
      screenRes: `${innerWidth}x${innerHeight}`,
    };

    return payload;
  };
  
  const startSurvey = async (e: any) => {
    const token = getLocalStorageItem(localStorageKeys.SURVEY_TOKEN, {takerId: surveyProps.takerId}) || "";
    fetchSurveyJson(token);
  };

  const saveResults =  async (json: any, surveyFlowCtrl: any) => {
    disableNextPageButtonOrCompleted();
    const token = getLocalStorageItem(localStorageKeys.SURVEY_TOKEN, {takerId: surveyProps.takerId}) || "";
    const { data } = await surveyTakerService.submitSurvey(token,prepareSurveyPayload(json, true, surveyFlowCtrl));
    if (data?.surveyToken) setLocalStorageItem(localStorageKeys.SURVEY_TOKEN, data.surveyToken.token, {takerId: surveyProps.takerId});
    enableNextPageButtonOrCompleted();
  };

  const onNavigateToUrl = (SurveyModel: any, NavigateToUrlEvent: any) => {
    NavigateToUrlEvent.allow = false;
    localStorage.setItem("navigateToUrl", NavigateToUrlEvent.url);
  };

  useEffect(() => {
    surveyDesign();
  }, []);

  const deleteSurveyResponse = async() => {
    const token = getLocalStorageItem(localStorageKeys.SURVEY_TOKEN, {takerId: surveyProps.takerId}) || "";
    if (token) {
      const response = await surveyTakerService.deleteSurveyResponse(token);
      if (!response.data) {
        return;
      }
    }
  }

  const saveUserFlowPosition = (flowStep: any, parent: any, actionIndex: number, noOfPagesRender: number) => {
    setLocalStorageItem(localStorageKeys.USER_SURVEY_FLOW, JSON.stringify({ flowStep, parent, actionIndex, noOfPagesRender: noOfPagesRender }), { takerId: surveyProps.takerId });
  }

  return (
    <>
      {errorClose ? (
        <Result status="warning" title={ errorMessage || "This survey is closed"} />
      ) : (
        <>
          {isSurveyTaken && (
            <Spin spinning={loading} size="large">
              {!isEmpty(surveyResponse) && <SurveyTaker
                surveyData={surveyResponse}
                prevResponse={prevResponse}
                options={{
                  onSurveyComplete: surveyComplete,
                  onNavigateToUrl: onNavigateToUrl,

                  saveResultsAction: saveResults,
                  saveUserFlowPositionAction: saveUserFlowPosition,
                  userFlowPosition: getLocalStorageItem(localStorageKeys.USER_SURVEY_FLOW, {takerId: surveyProps.takerId}) || "",
                  redirectUrlAction: (url: string) => {
                    setLocalStorageItem(localStorageKeys.FLOW_REDIRECT, url, {takerId: surveyProps.takerId});
                  },
                }
              }/>}

              <ReCAPTCHA
                sitekey={RECAPTCHAV3_KEY}
                size={"invisible"}
                ref={reRef}
              />
            </Spin>
          )}
          {!isSurveyTaken && (
            <Result
              status="warning"
              title={errorMessage}
              extra={
                <Button
                  type="primary"
                  key="console"
                  onClick={(e: any) => startSurvey(e)}
                >
                  Start Survey
                </Button>
              }
            />
          )}
        </>
      )}
    </>
  );
};

export default TakeSurveyWrapper;
